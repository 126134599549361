import React, { createContext, useReducer } from 'react';

export const UserContext = createContext();

const initialState = {
  user: JSON.parse(localStorage.getItem('user')) || null,
  isAuthenticated: !!localStorage.getItem('user')
};

const userReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_USER':
      if (action.payload) {
        localStorage.setItem('user', JSON.stringify(action.payload));
      } else {
        localStorage.removeItem('user');
      }
      return {
        ...state,
        user: action.payload,
        isAuthenticated: !!action.payload
      };
    default:
      return state;
  }
};

export const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(userReducer, initialState);

  const updateUser = (userData) => {
    dispatch({ type: 'UPDATE_USER', payload: userData });
  };

  return (
    <UserContext.Provider value={{ ...state, updateUser }}>
      {children}
    </UserContext.Provider>
  );
};
