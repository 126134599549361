import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styles from './BookSlot.module.css';
import { sportsOptions } from '../constants/constants'; // Import sportsOptions

const BookSlot = () => {
  const navigate = useNavigate();

  const getDefaultDate = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    return tomorrow;
  };

  const getDefaultTime = () => {
    const time = new Date();
    time.setHours(8, 0, 0, 0);
    return time;
  };

  const [date, setDate] = useState(getDefaultDate());
  const [time, setTime] = useState(getDefaultTime());
  const [duration, setDuration] = useState(60); // Default to 1 hour
  const [distance, setDistance] = useState(1); // Default to 1 km
  const [sport, setSport] = useState(sportsOptions[0].value); // Default to the first sport
  const [numCourts, setNumCourts] = useState(1); // Default to 1 court
  const [location, setLocation] = useState(null);
  const [postalCode, setPostalCode] = useState(''); // State for postal code
  const [fetchingPosition, setFetchingPosition] = useState(true);
  const [activeTab, setActiveTab] = useState('GPS'); // State for active tab

  useEffect(() => {
    // Request location permission and get location
    navigator.geolocation.getCurrentPosition(
      (position) => {
        console.log('Got location:', position);
        setLocation(position);
        setFetchingPosition(false);
      },
      (error) => {
        console.log(error);
        alert('Error: Could not get location. Make sure location services are enabled.');
        setFetchingPosition(false);
      }
    );
  }, []);

  const handleSearch = async () => {
    const formattedDate = date.toISOString().split('T')[0];
    const formattedTime = time.toTimeString().split(' ')[0].substring(0, 5);

    try {
      let params = {
        date: formattedDate,
        startTime: formattedTime,
        duration,
        distance,
        sport, // Include sport in the params
        numCourts, // Include number of courts in the params
      };

      if (activeTab === 'GPS') {
        if (!location) {
          alert('Error: Location not available. Please ensure location services are enabled.');
          return;
        }

        const { latitude, longitude } = location.coords;
        const formattedLatitude = latitude.toFixed(15);
        const formattedLongitude = longitude.toFixed(15);

        params = { ...params, lat: formattedLatitude, long: formattedLongitude };

        const response = await axios.get('https://www.tofubooking.online/api/bookings/available-courts-gps', { params });
        console.log('Server response:', response.data);
        navigate('/search-results', {
          state: {
            results: response.data,
            numCourts: numCourts,
            slotInfo: {
              date: formattedDate,
              time: formattedTime,
              duration,
              sport,
              numCourts,
            },
          },
        });
      } else {
        if (!postalCode) {
          alert('Error: Please enter a valid postal code.');
          return;
        }

        params = { ...params, postalCode };

        const response = await axios.get('https://www.tofubooking.online/api/bookings/available-courts-postalcode', { params });
        console.log('Server response:', response.data);
        navigate('/search-results', {
          state: {
            results: response.data,
            numCourts: numCourts,
            slotInfo: {
              date: formattedDate,
              time: formattedTime,
              duration,
              sport,
              numCourts,
            },
          },
        });
      }
    } catch (error) {
      console.error(error);
      alert('Error: Failed to fetch available courts.');
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.logoContainer}>
        <img src="/logo/logo.png" alt="Logo" className={styles.logo} />
      </div>
      <div className={styles.tabContainer}>
        <button className={`${styles.tab} ${activeTab === 'GPS' ? styles.activeTab : ''}`} onClick={() => setActiveTab('GPS')}>
          GPS
        </button>
        <button className={`${styles.tab} ${activeTab === 'PostalCode' ? styles.activeTab : ''}`} onClick={() => setActiveTab('PostalCode')}>
          Postal Code
        </button>
      </div>
      {activeTab === 'GPS' && fetchingPosition && <p className={styles.loading}>Fetching location...</p>}
      {!fetchingPosition && (
        <div className={styles.formContainer}>
          {activeTab === 'PostalCode' && (
            <div className={styles.inputGroup}>
              <label className={styles.label}>Postal Code</label>
              <input
                type="text"
                value={postalCode}
                onChange={(e) => setPostalCode(e.target.value)}
                className={styles.input}
              />
            </div>
          )}
          <div className={styles.inputGroup}>
            <label className={styles.label}>Date</label>
            <input
              type="date"
              value={date.toISOString().split('T')[0]}
              onChange={(e) => setDate(new Date(e.target.value))}
              className={styles.input}
            />
          </div>
          <div className={styles.inputGroup}>
            <label className={styles.label}>Time</label>
            <input
              type="time"
              value={time.toTimeString().split(' ')[0].substring(0, 5)}
              onChange={(e) => setTime(new Date(date.toDateString() + ' ' + e.target.value))}
              className={styles.input}
            />
          </div>
          <div className={styles.row}>
            <div className={styles.halfPickerContainer}>
              <label className={styles.label}>Duration</label>
              <select value={duration} onChange={(e) => setDuration(Number(e.target.value))} className={styles.picker}>
                <option value={30}>30 Minutes</option>
                <option value={60}>1 Hour</option>
                <option value={90}>1 Hour 30 Minutes</option>
                <option value={120}>2 Hours</option>
              </select>
            </div>
            <div className={styles.halfPickerContainer}>
              <label className={styles.label}>Number of Courts</label>
              <select value={numCourts} onChange={(e) => setNumCourts(Number(e.target.value))} className={styles.picker}>
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
              </select>
            </div>
          </div>
          <div className={styles.inputGroup}>
            <label className={styles.label}>Distance</label>
            <select value={distance} onChange={(e) => setDistance(Number(e.target.value))} className={styles.picker}>
              <option value={1}>1 km</option>
              <option value={3}>3 km</option>
              <option value={5}>5 km</option>
              <option value={10}>10 km</option>
              <option value={20}>20 km</option>
            </select>
          </div>
          <div className={styles.inputGroup}>
            <label className={styles.label}>Sport</label>
            <select value={sport} onChange={(e) => setSport(e.target.value)} className={styles.picker}>
              {sportsOptions.map(option => (
                <option key={option.value} value={option.value}>{option.label}</option>
              ))}
            </select>
          </div>
          <button
            className={`${styles.searchButton} ${activeTab === 'GPS' && !location ? styles.disabledButton : ''}`}
            onClick={handleSearch}
            disabled={activeTab === 'GPS' && !location}
          >
            Search
          </button>
        </div>
      )}
    </div>
  );
};

export default BookSlot;
