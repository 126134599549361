import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import styles from './MainMenu.module.css';

const MainMenu = () => {
  const { user, updateUser } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      const storedUser = localStorage.getItem('user');
      if (storedUser) {
        updateUser(JSON.parse(storedUser));
      }
    }
  }, [user, updateUser]);

  return (
    <div className={styles.container}>
      <div className={styles.logoContainer}>
        <img src="/logo/logo.png" alt="Logo" className={styles.logo} />
      </div>
      <div className={styles.content}>
        <h1 className={styles.title}>Welcome {user?.username}!</h1>
        <button className={styles.button} onClick={() => navigate('/qr-booking')}>
          QR Booking
        </button>
        <button className={styles.button} onClick={() => navigate('/link-booking')}>
          Link Booking
        </button>
        <button className={styles.button} onClick={() => navigate('/book-slot')}>
          Book a Slot
        </button>
        <button className={styles.button} onClick={() => navigate('/my-bookings')}>
          My Bookings
        </button>
        <button className={styles.button} onClick={() => navigate('/group-feature')}>
          Group Feature
        </button>
        <button className={styles.button} onClick={() => navigate('/preferences')}>
          Preferences
        </button>
      </div>
    </div>
  );
};

export default MainMenu;
