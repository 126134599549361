import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './SearchResults.module.css';

const SearchResults = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { results, numCourts, slotInfo } = location.state;

  const uniqueClubs = results.reduce((acc, result) => {
    const existingClub = acc.find(club => club.club_name === result.club_name);
    if (existingClub) {
      existingClub.courtsInfo.push({ courtId: result.court._id, courtName: result.court.name });
    } else {
      acc.push({
        club_name: result.club_name,
        location: result.location,
        phone: result.phone,
        distance: result.distance,
        courtsInfo: [{ courtId: result.court._id, courtName: result.court.name }],
        userId: result.userId,
        payment: result.payment,
      });
    }
    return acc;
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.logoContainer}>
        <img src="/logo/logo.png" alt="Logo" className={styles.logo} />
      </div>
      <div className={styles.innerContainer}>
        {uniqueClubs.map((club, index) => (
          <div
            key={index}
            className={styles.resultItem}
            onClick={() => navigate('/club-details', { state: { club, slotInfo, numCourts } })}
          >
            <div className={styles.resultTextContainer}>
              <p className={styles.resultText}>{club.club_name}</p>
              <p className={styles.resultSubText}>{club.location}</p>
            </div>
            {club.distance && (
              <p className={styles.distanceText}>{club.distance} km</p>
            )}
          </div>
        ))}
        <button className={styles.backButton} onClick={() => navigate(-1)}>
          Back
        </button>
      </div>
    </div>
  );
};

export default SearchResults;
