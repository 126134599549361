import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { FaCheckCircle } from 'react-icons/fa';
import styles from './BookingDetails.module.css';

const BookingDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { bookingDetailsArray, bookingData, numCourts, totalPrice, ownerId } = location.state;
  const sharedDetails = bookingDetailsArray[0]; // Using the first booking as shared details
  console.log('Booking details:', bookingData);
  const [userSettings, setUserSettings] = useState(null);

  useEffect(() => {
    const fetchUserSettings = async () => {
      try {
        const response = await axios.get(`https://www.tofubooking.online/api/owners/owner/${ownerId}`);
        setUserSettings(response.data.settings);
      } catch (error) {
        console.error('Error fetching user settings:', error);
        alert('Error: Failed to fetch user settings.');
      }
    };

    fetchUserSettings();
  }, [ownerId]);

  const handleConfirmation = async () => {
    let response;
    for (var i=0;i<bookingData.length;i++){
      response = await axios.post('https://www.tofubooking.online/api/bookings', bookingData[i]);
    }
    
    console.log('Booking response:', response.data);
    navigate('/booking-confirmation', { state: { bookingDetailsArray, userSettings,numCourts, price: sharedDetails.price, bookings: bookingData,bookingId: response.data._id } });
  };

  return (
    <div className={styles.container}>
      <div className={styles.logoContainer}>
        <img src="/logo/logo.png" alt="Logo" className={styles.logo} />
      </div>
      <div className={styles.detailsContainer}>
        <h1 className={styles.title}>Booking Details</h1>

        <div className={styles.card}>
          <h2 className={styles.sectionTitle}>Court Information</h2>
          <p className={styles.detail}><span className={styles.label}>Court:</span> {bookingDetailsArray.map(details => details.courtName).join(', ')}</p>
          <p className={styles.detail}><span className={styles.label}>Sport:</span> {sharedDetails.sport}</p>
        </div>

        <div className={styles.card}>
          <h2 className={styles.sectionTitle}>Booking Time</h2>
          <p className={styles.detail}><span className={styles.label}>Start Time:</span> {moment.utc(sharedDetails.date + ' '+ sharedDetails.time).format('DD-MM-YYYY HH:mm')}</p>
          <p className={styles.detail}><span className={styles.label}>Duration:</span> {sharedDetails.duration} min</p>
        </div>

        {userSettings && (
          <div className={styles.card}>
            <h2 className={styles.sectionTitle}>Payment Information</h2>
            <p className={styles.detail}><span className={styles.label}>Price:</span> {totalPrice ? `${totalPrice.toLocaleString()} VND` : 'Calculating...'}</p>
            <p className={styles.detail}><span className={styles.label}>Accept Payment at Court:</span> {userSettings.acceptPaymentAtCourt ? 'Yes' : 'No'}</p>
            <p className={styles.detail}><span className={styles.label}>Bank Account:</span> {userSettings.bankingInformation.bankAccount}</p>
            <p className={styles.detail}><span className={styles.label}>Bank Holder:</span> {userSettings.bankingInformation.bankHolder}</p>
            <p className={styles.detail}><span className={styles.label}>Bank Name:</span> {userSettings.bankingInformation.bankName}</p>
            {sharedDetails.paymentProof ? (
              <div className={styles.paymentSection}>
                <img src={sharedDetails.paymentProof} alt="Payment Proof" className={styles.image} />
                <FaCheckCircle className={styles.icon} color="green" />
                <span className={styles.iconLabel}>Payment done</span>
              </div>
            ) : (
              <div className={styles.paymentSection}>
                <button className={styles.button} onClick={handleConfirmation}>
                  Confirmation
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default BookingDetails;
