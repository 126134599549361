import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaCopy } from 'react-icons/fa';
import axios from 'axios';
import styles from './BookingConfirmation.module.css';

const BookingConfirmation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { bookingDetailsArray, numCourts, totalPrice, bookingId } = location.state;

  const [paymentProof, setPaymentProof] = useState(null);
  const [uploadSuccess, setUploadSuccess] = useState(false);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      alert('Copied to Clipboard: Bank account copied to clipboard!');
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result; // Keep the data URI prefix
        setPaymentProof(base64String);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileUpload = async () => {
    if (paymentProof) {
      try {
        await axios.put(`https://www.tofubooking.online/api/bookings/${bookingId}/payment-proof`, { paymentProof });
        alert('Payment proof uploaded successfully!');
        setUploadSuccess(true);
      } catch (error) {
        console.error('Error uploading payment proof:', error);
        alert('Failed to upload payment proof. Please try again.');
      }
    } else {
      alert('Please select a file first.');
    }
  };

  const sharedDetails = bookingDetailsArray[0];
  const courtName = bookingDetailsArray.map(details => details.courtName).join(', ');

  return (
    <div className={styles.container}>
      <div className={styles.logoContainer}>
        <img src="/logo/logo.png" alt="Logo" className={styles.logo} />
      </div>
      <h1 className={styles.title}>Booking Confirmed!</h1>
      <div className={styles.section}>
        <h2 className={styles.sectionTitle}>Number of Courts Booked: {bookingDetailsArray.length}</h2>
        <div className={styles.section}>
          <h3 className={styles.sectionTitle}>Club Information</h3>
          <p className={styles.label}>Club Name:</p>
          <p className={styles.value}>{sharedDetails.clubName}</p>
          <p className={styles.label}>Location:</p>
          <p className={styles.value}>{sharedDetails.location}</p>
          <p className={styles.label}>Court Name:</p>
          <p className={styles.value}>{courtName}</p>
        </div>
        <div className={styles.section}>
          <h3 className={styles.sectionTitle}>Booking Details</h3>
          <div className={styles.row}>
            <div className={styles.column}>
              <p className={styles.label}>Date:</p>
              <p className={styles.value}>{sharedDetails.date}</p>
            </div>
            <div className={styles.column}>
              <p className={styles.label}>Time:</p>
              <p className={styles.value}>{sharedDetails.time}</p>
            </div>
          </div>
          <p className={styles.label}>Duration:</p>
          <p className={styles.value}>{sharedDetails.duration} minutes</p>
        </div>
        <div className={styles.section}>
          <h3 className={styles.sectionTitle}>Payment Information</h3>
          <p className={styles.label}>Total Price:</p>
          <p className={styles.value}>{totalPrice}</p>
          <p className={styles.label}>Accept Payment at Court:</p>
          <p className={styles.value}>{sharedDetails.payment.acceptPaymentAtCourt ? 'Yes' : 'No'}</p>
          <p className={styles.label}>Bank Account:</p>
          <div className={styles.row}>
            <p className={styles.value}>{sharedDetails.payment.bankInformation.bankAccount}</p>
            <button className={styles.copyButton} onClick={() => copyToClipboard(sharedDetails.payment.bankInformation.bankAccount)}>
              <FaCopy size={20} color="#ff4757" />
            </button>
          </div>
          <p className={styles.label}>Bank Holder:</p>
          <p className={styles.value}>{sharedDetails.payment.bankInformation.bankHolder}</p>
          <p className={styles.label}>Bank Name:</p>
          <p className={styles.value}>{sharedDetails.payment.bankInformation.bankName}</p>
        </div>
        {uploadSuccess ? (
          <div className={styles.section}>
            <h3 className={styles.sectionTitle}>Payment Proof</h3>
            <p className={styles.successMessage}>Your proof of payment has been sent! A confirmation will be sent to you shortly.</p>
          </div>
        ) : (
          <div className={styles.section}>
            <h3 className={styles.sectionTitle}>Upload Proof Of Payment</h3>
            <input type="file" onChange={handleFileChange} className={styles.fileInput} />
            <button className={styles.uploadButton} onClick={handleFileUpload}>
              Upload
            </button>
          </div>
        )}
        <button className={styles.returnButton} onClick={() => navigate('/mainmenu')}>
          Return
        </button>
      </div>
    </div>
  );
};

export default BookingConfirmation;
