import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import SignIn from './components/SignIn/SignIn';
import MainMenu from './components/MainMenu/MainMenu';
import BookSlot from './components/Booking/BookSlot';
import SearchResults from './components/SearchResults/SearchResults';
import ClubSearchDetails from './components/ClubSearchDetails/ClubSearchDetails';
import BookingConfirmation from './components/BookingConfirmation/BookingConfirmation';
import BookingDetails from './components/BookingDetails/BookingDetails';
import GuestMenu from './components/GuestMenu/GuestMenu'; // Import GuestMenu
import MyBookingsScreen from './components/MyBookings/MyBookings'; // Import MyBookingsScreen
import { UserContext, UserProvider } from './components/context/UserContext';

function AppContent() {
  const { user } = useContext(UserContext);
  console.log('User:', user);
  return (
    <Router>
      <Routes>
        <Route path="/signin" element={(user && user.role !== 'guest') ? <Navigate to="/mainmenu" /> : <SignIn />} />
        <Route path="/mainmenu" element={user ? <MainMenu /> : <Navigate to="/signin" />} />
        <Route path="/book-slot" element={<BookSlot />} />
        <Route path="/search-results" element={<SearchResults />} />
        <Route path="/club-details" element={<ClubSearchDetails />} />
        <Route path="/booking-confirmation" element={user ? <BookingConfirmation /> : <Navigate to="/signin" />} />
        <Route path="/booking-details" element={user ? <BookingDetails /> : <Navigate to="/signin" />} />
        <Route path="/guestmenu" element={<GuestMenu />} /> {/* Add GuestMenu route */}
        <Route path="/my-bookings" element={user ? <MyBookingsScreen /> : <Navigate to="/signin" />} /> {/* Add MyBookingsScreen route */}
        <Route path="/" element={(user && user.role !== 'guest') ? <Navigate to="/mainmenu" /> : <Navigate to="/signin" />} />
      </Routes>
    </Router>
  );
}

function App() {
  return (
    <UserProvider>
      <AppContent />
    </UserProvider>
  );
}

export default App;
