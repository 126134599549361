import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import styles from './ClubSearchDetails.module.css';
import { UserContext } from '../context/UserContext';

const ClubDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { club, slotInfo, numCourts } = location.state;
  const { user } = useContext(UserContext);
  const [mapImage, setMapImage] = useState(null);
  const [totalPrice, setTotalPrice] = useState(null);
  const [priceError, setPriceError] = useState(false);
  const [guestEmail, setGuestEmail] = useState('');

  useEffect(() => {
    const fetchMapImage = async () => {
      try {
        const response = await axios.get(`https://www.tofubooking.online/api/owners/mapImage/${club.userId}`);
        setMapImage(response.data.mapImage);
      } catch (error) {
        console.error(error);
        alert('Error: Failed to load map image.');
      }
    };

    fetchMapImage();
  }, [club.userId]);

  useEffect(() => {
    const fetchPrice = async () => {
      try {
        const response = await axios.get('https://www.tofubooking.online/api/owners/calculate-price', {
          params: {
            ownerId: club.userId,
            sport: slotInfo.sport,
            startSlot: moment.utc(`${slotInfo.date}T${slotInfo.time}:00`).toISOString(),
            endSlot: moment.utc(`${slotInfo.date}T${slotInfo.time}:00`).add(slotInfo.duration, 'minutes').toISOString()
          }
        });
        const price = response.data.totalPrice * numCourts;
        setTotalPrice(price);
        setPriceError(false);
      } catch (error) {
        console.error(error);
        setPriceError(true);
        alert('Error: Failed to calculate the price.');
      }
    };

    fetchPrice();
  }, [club.userId, slotInfo.date, slotInfo.time, slotInfo.duration, slotInfo.sport, numCourts]);

  const handleBooking = () => {
    try {
      const bookingDetailsArray = [];
      const bookingArray = [];
      for (let i = 0; i < numCourts; i++) {
        const courtId = club.courtsInfo[i].courtId;
        const courtName = club.courtsInfo[i].courtName;
        const bookingData = {
          courtId: courtId,
          ownerId: club.userId,
          userId: user?.userId || null,
          userMail: user?.role === 'guest' ? guestEmail : user?.userMail,
          startSlot: moment.utc(`${slotInfo.date}T${slotInfo.time}:00`).toISOString(),
          endSlot: moment.utc(`${slotInfo.date}T${slotInfo.time}:00`).add(slotInfo.duration, 'minutes').toISOString(),
          status: 'Pending',
          type: 'App',
          price: {
            'currency': 'VND',
            'amount': totalPrice
          }
        };
        bookingArray.push(bookingData);
        bookingDetailsArray.push({
          clubName: club.club_name,
          sport: slotInfo.sport,
          location: club.location,
          date: slotInfo.date,
          time: slotInfo.time,
          duration: slotInfo.duration,
          payment: club.payment,
          courtId: courtId,
          courtName: courtName,
          price: {
            'currency': 'VND',
            'amount': totalPrice
          }
        });
      }
      navigate('/booking-details', { state: { bookingDetailsArray, bookingData: bookingArray, numCourts, totalPrice, ownerId: club.userId } });
    } catch (error) {
      console.error(error);
      alert('Error: Failed to book the slot.');
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.logoContainer}>
        <img src="/logo/logo.png" alt="Logo" className={styles.logo} />
      </div>
      {mapImage ? (
        <img src={mapImage} alt="Map" className={styles.mapImage} />
      ) : (
        <p className={styles.loadingText}>Loading map...</p>
      )}
      <div className={styles.detailsContainer}>
        <h1 className={styles.title}>{club.club_name}</h1>
        <p className={styles.subtitle}>{club.location}</p>
        <p className={styles.label}>Requested Slot:</p>
        <p className={styles.value}>{numCourts} Courts</p>
        <p className={styles.value}>{slotInfo.date} at {slotInfo.time}</p>
        {user?.role === 'guest' && (
          <div className={styles.emailContainer}>
            <label className={styles.label}>Inform your mail to follow your booking request:</label>
            <input
              type="email"
              value={guestEmail}
              onChange={(e) => setGuestEmail(e.target.value)}
              className={styles.input}
            />
          </div>
        )}
        {priceError ? (
          <p className={styles.errorText}>Contact the club at {club.phone} to complete your booking</p>
        ) : (
          <>
            <p className={styles.label}>Total Price:</p>
            <p className={styles.value}>{totalPrice ? `${totalPrice.toLocaleString()} VND` : 'Calculating...'}</p>
          </>
        )}
        <button className={styles.bookButton} onClick={handleBooking} disabled={priceError || (user?.role === 'guest' && !guestEmail)}>
          Validate Booking
        </button>
      </div>
    </div>
  );
};

export default ClubDetails;
