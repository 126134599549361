import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { UserContext } from '../context/UserContext';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import styles from './SignIn.module.css';

const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { updateUser } = useContext(UserContext);
  const navigate = useNavigate();

  const handleSignIn = async () => {
    if (!email || !password) {
      alert('Error: Please enter both email and password');
      return;
    }

    try {
      const response = await axios.post('https://www.tofubooking.online/api/users/login', { email, password });
      if (response.status === 200) {
        const { role, username, ...userData } = response.data;
        updateUser(userData);
        localStorage.setItem('user', JSON.stringify(userData));
        navigate('/mainmenu');
      } else {
        alert('Login Failed: Invalid credentials, please try again.');
      }
    } catch (error) {
      console.error('Login error:', error);
      alert('Login Error: ' + JSON.stringify(error));
    }
  };

  const handleGuestSignIn = () => {
    updateUser({ role: 'guest' });
    navigate('/guestmenu');
  };

  const handleGoogleLoginSuccess = async (response) => {
    try {
      const res = await axios.post('https://www.tofubooking.online/api/users/google-login', {
        tokenId: response.credential,
      });
      const { role, username, ...userData } = res.data;
      updateUser(userData);
      localStorage.setItem('user', JSON.stringify(userData));
      navigate('/mainmenu');
    } catch (error) {
      console.error('Google login error:', error);
      alert('Google Login Error: ' + JSON.stringify(error));
    }
  };

  const handleGoogleLoginFailure = (response) => {
    console.error('Google login failure:', response);
    alert('Google Login Failed. Please try again.');
  };

  return (
    <GoogleOAuthProvider clientId="524251809607-ne7bscpg5r24rgfk5igtt7ecjgkp3btm.apps.googleusercontent.com">
      <div className={styles.container}>
        <div className={styles.logoContainer}>
          <img src="/logo/logo.png" alt="Logo" className={styles.logo} />
        </div>
        <div className={styles.formContainer}>
          <h1 className={styles.title}>Sign In</h1>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={styles.input}
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className={styles.input}
          />
          <button onClick={handleSignIn} className={styles.button}>
            Sign In
          </button>
          <button onClick={handleGuestSignIn} className={styles.guestButton}>
            Guest
          </button>
          <div className={styles.googleLoginContainer}>
            <GoogleLogin
              onSuccess={handleGoogleLoginSuccess}
              onFailure={handleGoogleLoginFailure}
              render={(renderProps) => (
                <button
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                  className={styles.googleButton}
                >
                  Sign In with Google
                </button>
              )}
            />
          </div>
          <div className={styles.spacing}></div>
          <div className={styles.linkContainer}>
            <button onClick={() => {}} className={styles.link}>Forgot Password?</button>
            <button onClick={() => navigate('/register')} className={styles.link}>Sign Up</button>
          </div>
        </div>
      </div>
    </GoogleOAuthProvider>
  );
};

export default SignIn;
