import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { FaCreditCard, FaCheckCircle } from 'react-icons/fa';
import styles from './MyBookings.module.css';
import { UserContext } from '../context/UserContext';

const MyBookingsScreen = () => {
  const [groupedBookings, setGroupedBookings] = useState([]);
  const { user } = useContext(UserContext); // Use UserContext to get the user
  const [email, setEmail] = useState(user.userMail || ''); // Set email state
  const [emailEntered, setEmailEntered] = useState(!!user.userMail); // Check if email is entered
  const navigate = useNavigate();

  const fetchBookings = async (email) => {
    try {
      const response = await axios.get(`https://www.tofubooking.online/api/bookings/usermail/${email}`);
      const bookings = response.data.filter(booking => booking.courtId); // Filter out bookings where courtId is null or undefined
      const pending = bookings.filter(booking => booking.status === 'Pending');
      const confirmed = bookings.filter(booking => 
        booking.status === 'Confirmed' && moment.utc(booking.startSlot).isAfter(moment.utc().startOf('day'))
      );

      const grouped = groupBookingsByTimeAndSport(pending);
      setGroupedBookings(grouped);
    } catch (error) {
      console.error('Error fetching bookings:', error);
      alert('Error: Failed to fetch bookings.');
    }
  };

  useEffect(() => {
    if (user.userMail) {
      fetchBookings(user.userMail);
    }
  }, [user]);

  const groupBookingsByTimeAndSport = (bookings) => {
    const grouped = [];

    bookings.forEach(booking => {
      const key = `${booking.startSlot}_${booking.endSlot}_${booking.ownerId}_${booking.courtId.sport}`;
      const existingGroup = grouped.find(group => group.key === key);

      if (existingGroup) {
        existingGroup.courts.push(booking.courtId.name);
      } else {
        grouped.push({
          key,
          courts: [booking.courtId.name],
          booking,
        });
      }
    });
    return grouped;
  };

  const renderBookingItem = (item) => (
    <div
      key={item.key}
      className={`${styles.bookingItem} ${item.booking.status === 'Pending' ? styles.pendingBackground : styles.confirmedBackground}`}
      onClick={() => navigate('/booking-details', { state: { booking: item.booking, courts: item.courts }})}
    >
      <div className={styles.bookingInfo}>
        <p className={styles.bookingText}>Court: {item.courts.join(', ')}</p>
        <p className={styles.bookingText}>Sport: {item.booking.courtId.sport}</p>
        <p className={styles.bookingText}>Booking at {moment.utc(item.booking.startSlot).format('DD-MM-YYYY HH:mm')}</p>
      </div>
      <div className={styles.iconContainer}>
        {!item.booking.paymentProof ? (
          <FaCreditCard size={24} color="#fff" />
        ) : (
          <FaCheckCircle size={24} color="#fff" />
        )}
      </div>
    </div>
  );

  const handleEmailSubmit = () => {
    if (email) {
      setEmailEntered(true);
      fetchBookings(email);
    } else {
      alert('Please enter a valid email address.');
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.logoContainer}>
        <img src="/logo/logo.png" alt="Logo" className={styles.logo} />
      </div>
      {!emailEntered ? (
        <div className={styles.formContainer}>
          <label className={styles.label}>Enter your email to view bookings:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={styles.input}
          />
          <button onClick={handleEmailSubmit} className={styles.button}>
            Submit
          </button>
        </div>
      ) : (
        <>
          <h2 className={styles.sectionTitle}>Pending Bookings</h2>
          <div className={styles.bookingList}>
            {groupedBookings.length > 0 ? (
              groupedBookings.map(item => renderBookingItem(item))
            ) : (
              <p className={styles.emptyText}>No pending bookings</p>
            )}
          </div>
          <h2 className={styles.sectionTitle}>Confirmed Bookings</h2>
          <div className={styles.bookingList}>
            {groupedBookings.filter(item => item.booking.status === 'Confirmed').length > 0 ? (
              groupedBookings.filter(item => item.booking.status === 'Confirmed').map(item => renderBookingItem(item))
            ) : (
              <p className={styles.emptyText}>No confirmed bookings</p>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default MyBookingsScreen;
