import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './GuestMenu.module.css';

const GuestMenu = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <div className={styles.logoContainer}>
        <img src="/logo/logo.png" alt="Logo" className={styles.logo} />
      </div>
      <div className={styles.content}>
        <h1 className={styles.title}>Welcome, Guest!</h1>
        <button className={styles.button} onClick={() => navigate('/book-slot')}>
          Book a Slot
        </button>
        <button className={styles.button} onClick={() => navigate('/my-bookings')}>
          My Bookings
        </button>
      </div>
    </div>
  );
};

export default GuestMenu;
